import React from "react"
import { ColorPalette } from "../Theme"

/** svg */
import ValuesIcon from "../../../assets/values.svg"
import BenefitsHealthPlan from "../../../assets/benefits-health-plan.svg"
import Star from "../../../assets/star-icon.svg"
import PdfIcon from "../../../assets/pdf-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** const */
const BenefitsMedical: React.FC = () => {
  return (
    <section className="container-fluid pt-5 pb-5" style={{ background: "#EFF2F4" }}>
      <div className="container pl-1 pr-1">
        <div>
          <div className="text-center pb-1">
            <ValuesIcon />
          </div>
        </div>
        <div className="contentHeroSpace hidden-xs"></div>
        <h1 className="contentHeroTitle" style={{ color: "#365c70" }}>
          Norhart Health Plans
        </h1>
        <h2 className="contentHeroSubTitle mb-5" style={{ color: "#365c70", opacity: "0.7" }}>
          We consider our employees "You" our most valuable asset! Taking care of you and your families is our top
          priority. We designed these "Best In Class" heath plans just for you!
        </h2>

        <div className="container">
          <div className="row">
            <div className="col-md-4 animate__animated animate__slideOutUp pb-4">
              <div className="ibox">
                <div className="ibox-content product-box bct-1">
                  <div className="product-desc">
                    <div className="mb-3">
                      <BenefitsHealthPlan />
                      <br />
                    </div>
                    <small className="product-name text-muted mt-3">
                      Gold Plan
                      <br />
                      <img src="/benefits/5-star-rating.png" />
                    </small>
                    <hr />
                    <span className="product-name">Starts at $150/paycheck</span>
                    <div className="small m-t-xs">
                      The Gold Plan starts at $150 per paycheck and goes up to $300 per paycheck for a family plan.
                    </div>

                    <div className="small m-t-xs mt-4">
                      <strong>Pricing:</strong>
                      <br />
                      • Individual: $150
                      <br />
                      • Individual+: $300
                      <br />• Family: $450
                    </div>

                    <div className="small m-t-xs mt-4">
                      <strong>Deductible:</strong>
                      <br />
                      • Individual: $1,650
                      <br />
                      • Individual+: $3,300
                      <br />• Family: $3,300
                    </div>

                    <div className="small m-t-xs mt-4">
                      <strong>Out Of Pocket Max:</strong>
                      <br />
                      • Individual: $3,300
                      <br />
                      • Individual+: $6,600
                      <br />• Family: $6,600
                    </div>

                    <div className="col-1 chartContent-center">
                      <a href="/benefits/pdf/norhart-gold-medical-plan.pdf" target="_blank">
                        <PdfIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 animate__animated animate__slideOutUp pb-4">
              <div className="ibox">
                <div className="ibox-content product-box bct-1">
                  <div className="product-desc">
                    <div className="mb-3">
                      <BenefitsHealthPlan />
                      <br />
                    </div>
                    <small className="product-name text-muted mt-3">
                      Silver Plan
                      <br />
                      <img src="/benefits/4-star-rating.png" />
                    </small>
                    <hr />
                    <span className="product-name">Starts at $125/paycheck</span>
                    <div className="small m-t-xs">
                      The Silver Plan starts at $125 per paycheck and goes up to $375 per paycheck for a family plan.
                    </div>

                    <div className="small m-t-xs mt-4">
                      <strong>Pricing:</strong>
                      <br />
                      • Individual: $125
                      <br />
                      • Individual+: $250
                      <br />• Family: $375
                    </div>

                    <div className="small m-t-xs mt-4">
                      <strong>Deductible:</strong>
                      <br />
                      • Individual: $3,300
                      <br />
                      • Individual+: $6,600
                      <br />• Family: $6,600
                    </div>

                    <div className="small m-t-xs mt-4">
                      <strong>Out Of Pocket Max:</strong>
                      <br />
                      • Individual: $6,600
                      <br />
                      • Individual+: $13,200
                      <br />• Family: $13,200
                    </div>

                    <div className="col-1 chartContent-center">
                      <a href="/benefits/pdf/norhart-silver-medical-plan.pdf" target="_blank">
                        <PdfIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 animate__animated animate__slideOutUp pb-4">
              <div className="ibox">
                <div className="ibox-content product-box bct-1">
                  <div className="product-desc">
                    <div className="mb-3">
                      <BenefitsHealthPlan />
                      <br />
                    </div>
                    <small className="product-name text-muted mt-3">
                      Bronze Plan
                      <br />
                      <img src="/benefits/3-star-rating.png" />
                    </small>
                    <hr />
                    <span className="product-name">Starts at $100/paycheck</span>
                    <div className="small m-t-xs">
                      The Bronze Plan starts at $100 per paycheck and goes up to $300 per paycheck for a family plan.
                    </div>

                    <div className="small m-t-xs mt-4">
                      <strong>Pricing:</strong>
                      <br />
                      • Individual: $100
                      <br />
                      • Individual+: $200
                      <br />• Family: $300
                    </div>

                    <div className="small m-t-xs mt-4">
                      <strong>Deductible:</strong>
                      <br />
                      • Individual: $4,500
                      <br />
                      • Individual+: $9,000
                      <br />• Family: $9,000
                    </div>

                    <div className="small m-t-xs mt-4">
                      <strong>Out Of Pocket Max:</strong>
                      <br />
                      • Individual: $7,000
                      <br />
                      • Individual+: $14,000
                      <br />• Family: $14,000
                    </div>

                    <div className="col-1 chartContent-center">
                      <a href="/benefits/pdf/norhart-bronze-medical-plan.pdf" target="_blank">
                        <PdfIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default BenefitsMedical
